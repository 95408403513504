@mixin phone {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin containerMax {
  @media (min-width: 1400px) {
    @content;
  }
}

$APPBAR_HEIGHT: 66px;
$DARK_THEME_BG_COLOR: #292a2c;
$PRIMARY_COLOR: #b57d62;

*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: $DARK_THEME_BG_COLOR;
  min-height: 100%;
  overflow-x: hidden !important;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: $APPBAR_HEIGHT;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.content-container {
  // position: relative;
  padding-left: 100px !important;
  padding-right: 100px !important;

  @include containerMax {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  @include phone {
    padding: 0 16px !important;
  }
}

//Hacks

.Mui-selected {
  color: $PRIMARY_COLOR !important;
  background-color: transparent !important;
}

.MuiOutlinedInput-input {
  padding-right: 14px !important;
}
.MuiOutlinedInput-adornedEnd {
  padding-right: 0px !important;
}

.MuiCardContent-root:last-child {
  padding: 0 !important;
}

.MuiDrawer-modal {
  z-index: 1199 !important;
}

.MuiDrawer-paperAnchorTop {
  top: $APPBAR_HEIGHT !important;
}

.MuiToolbar-regular {
  min-height: $APPBAR_HEIGHT !important;
}

.MuiInput-underline:before {
  display: none !important;
}
.MuiInput-underline:after {
  display: none !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

//Helpers

.mt-1 {
  margin-top: 4px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 16px !important;
}
.mt-4 {
  margin-top: 32px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.mb-3 {
  margin-bottom: 16px !important;
}
.mb-4 {
  margin-bottom: 32px !important;
}
.ml-1 {
  margin-left: 4px !important;
}
.ml-2 {
  margin-left: 8px !important;
}
.ml-3 {
  margin-left: 16px !important;
}
.ml-4 {
  margin-left: 32px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mr-3 {
  margin-right: 16px !important;
}
.mr-4 {
  margin-right: 32px !important;
}
